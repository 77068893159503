import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import { withHomePageSection } from 'components/withHomePageSection';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { shadows } from 'style/shadows';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  margin: 32px 0;
  display: grid;
  gap: 24px;
  .item {
    display: grid;
    grid-auto-rows: min-content;
    gap: 8px;
    padding: 24px;
    border: 1px solid ${colorsV2.gray20};
    border-radius: 8px;
  }

  ${fromScreen(776)} {
    margin-top: -150px;
    margin-bottom: 0;
    padding: 24px;
    border-radius: 20px;
    background: ${colorsV2.white100};
    box-shadow: ${shadows.zIndex3};
    grid-template-columns: 1fr 1fr 1fr;
    .item {
      padding: 0;
      border: none;
    }
  }

  ${fromScreen(1144)} {
    gap: 58px;
    padding: 40px 80px;
  }
`;

const highlight = [
  {
    icon: '0TjszqdzR0mQEWiCdNRn',
    title: 'Kỹ năng sư phạm tốt',
    desc: 'Đội ngũ giáo viên nhiều năm kinh nghiệm, là Thạc sĩ, Thủ khoa, Á khoa, Giảng viên các trường Đại học danh tiếng. Có chứng chỉ giảng dạy quốc tế và được đào tạo bài bản.'
  },
  {
    icon: '0NabyWqXSfCFsVF2zpyF',
    title: 'Dạy học có tâm',
    desc: 'Đội ngũ giáo viên luôn đặt học viên của mình lên hàng đầu. Không chỉ giảng dạy và truyền đạt kiến thức tại lớp, giáo viên còn sẵn sàng hỗ trợ học viên ngoài giờ học thông qua Fanpage.'
  },
  {
    icon: '4OYKzHSNTkWRHefNvNVy',
    title: 'Linearthinking Ambassador',
    desc: 'Linearthinking là Hệ phương pháp học Tiếng Anh độc quyền của DOL IELTS Đình Lực. Đội ngũ của DOL được đào tạo bài bản để giúp học viên tiếp thu trọn vẹn và vận dụng được Linearthinking thành thạo nhất.'
  }
];

export const TeacherIntro = withHomePageSection(() => {
  return (
    <Main>
      {highlight.map((item, key) => (
        <div key={key} className="item">
          <LazyImage handle={item.icon} alt="icon-intro" />
          <Typography variant="semi-bold/20-28">{item.title}</Typography>
          <Typography variant="regular/14-20">{item.desc}</Typography>
        </div>
      ))}
    </Main>
  );
});
