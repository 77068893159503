import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';

export const SectionTitle = props => {
  const { content } = props;

  return (
    <Main>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        dangerouslySetInnerHTML={{ __html: content }}
        as="h2"
      />
    </Main>
  );
};

const Main = styled.div`
  margin-bottom: 24px;
  ${fromScreen(776)} {
    margin-bottom: 40px;
    br {
      display: none;
    }
  }
  ${fromScreen(1144)} {
    text-align: center;
  }
`;
