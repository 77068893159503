import * as React from 'react';

import styled from 'styled-components';
import MainLayout from 'components/Layout/MainLayout';
import AllDolTeachers from 'shared/DolTeachers/AllDolTeachers';
import MySEO from 'shared/MySEO';
import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';
import { AllDolTeacherHeader } from 'shared/DolTeachers/TeacherLandingPage/AllDolTeacherHeader';
import { TeacherIntro } from 'shared/DolTeachers/TeacherLandingPage/TeacherIntro';
import TeacherYoutubeVideo from 'shared/DolTeachers/TeacherLandingPage/TeacherYoutubeVideo';
import { withHomePageSection } from 'components/withHomePageSection';
import { fromScreen } from 'utils/media-query/responsive.util';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';

const Main = styled.div``;

const Space = styled.div`
  > div,
  section {
    padding: 32px 0;
    ${fromScreen(776)} {
      padding: 80px 0;
      &:first-child {
        padding: 100px 0 80px 0;
      }
      &:last-child {
        padding: 80px 0 100px 0;
      }
    }
  }
`;

const ContentSection = withHomePageSection(() => {
  return (
    <Space>
      <AllDolTeachers />
      <TeacherYoutubeVideo />
      <StudentReviews
        program="all"
        title="Tâm tình học viên dành cho giáo viên"
        columns={{ default: 3, 1143: 2, 775: 1 }}
      />
    </Space>
  );
});

const TeacherPage = ({ location }) => {
  return (
    <MainLayout showFooterRegister hideSideCTA>
      <MySEO
        slug={location.pathname}
        h1="Đội ngũ giáo viên tại DOL"
        hidePostfix
        title="Đội ngũ giáo viên - DOL ENGLISH"
        description="Đội ngũ giáo viên tại DOL English là những giáo viên giàu kinh nghiệm, tận tâm với nghề, nhiệt tình, thân thiện và có khả năng truyền đạt kiến thức tốt"
      />
      <Main className="teacher-page">
        <FixedTopBarScholarshipScheduleNOffline />
        <AllDolTeacherHeader />
        <TeacherIntro />
        <ContentSection />
      </Main>
    </MainLayout>
  );
};

export default TeacherPage;
